import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import store from './redux/store';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './index.css';
import awsExports from './awsmobile';
import { getProfile } from './store/profile.slice';
import { signIn } from './store/sign-in.slice';

Amplify.configure(awsExports);
let userAttributes;
let isAdmin = false;
let isLoggedIn = false;

axios.interceptors.request.use(
  async (req) => {
    await Auth.currentSession()
      .then((res) => {
        req.headers.Authorization = `Bearer ${res.accessToken.jwtToken}`;
        const userRole = res?.accessToken.payload['cognito:groups'];
        // eslint-disable-next-line no-unneeded-ternary
        isAdmin = userRole?.includes('Admin') ? true : false;

        isLoggedIn = true;
      })
      .catch(() => {
        isLoggedIn = false;
      });

    return req;
  },
  (err) => {
    isLoggedIn = false;
    return Promise.reject(err);
  }
);
// Checks the current user details
await Auth.currentAuthenticatedUser()
  .then(async (res) => {
    userAttributes = res.attributes;
    const userRole = res.signInUserSession?.accessToken.payload['cognito:groups'];
    // eslint-disable-next-line no-unneeded-ternary
    isAdmin = userRole?.includes('Admin') ? true : false;
    isLoggedIn = true;
    const payload = {
      userAttributes: res.attributes,
      isLoggedIn: true,
    };
    store.dispatch(signIn(payload));
    if (userAttributes.email != null) {
      await store.dispatch(getProfile(userAttributes.email));
    }
  })
  .catch(() => {
    isLoggedIn = false;
  });

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

function IsNotAdmin({ notAdmin, store }) {
  const handleLogout = async () => {
    try {
      await Auth.signOut();

      window.location = '/login';
    } catch (error) {
      console.log(error);
    }
  };

  if (notAdmin) {
    return (
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed top-0 right-0  z-50 w-full h-full bg-opacity-70 bg-gray-700"
      >
        <div className=" p-4 w-full max-w-2xl top-0 bottom-0 absolute right-0 left-0 m-auto h-min">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg h-5/6 shadow">
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 ">Access Restricted</h3>
            </div>
            {/* <!-- Modal body --> */}
            <div className="h-4/5">
              <div className="p-6 space-y-6 h-4/5 ">
                <Typography>Please contact Admin for granting access. Press Ok to logout.</Typography>
              </div>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="flex items-center  py-2 px-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600 bg-white h-20">
              <Button variant="contained" onClick={handleLogout} className="rounded-full p-3 px-5">
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

root.render(<IsNotAdmin notAdmin={!isAdmin && isLoggedIn} store={store} />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
