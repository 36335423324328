import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  lightSail: [],
  status: [],
  error: null,
};

/* Post Application review API Url */
const postLightSail = `${process.env.REACT_APP_API_BASE_URL}admin/lightstail/turn-on`;

const postLightSailOff = `${process.env.REACT_APP_API_BASE_URL}admin/lightstail/turn-off`;

const getLightSailStatus = `${process.env.REACT_APP_API_BASE_URL}admin/lightstail/blog-test-ghost-001`;
/* Post Application review */
export const postLightSailAction = createAsyncThunk('lightSail/postApplication', () => {
  const payload = {
    instance_name: 'blog-test-ghost-001',
  };
  return axios.post(postLightSail, payload).then((response) => response.data);
});

export const offLightSailAction = createAsyncThunk('lightSail/offLightSailAction', () => {
  const payload = {
    instance_name: 'blog-test-ghost-001',
  };
  return axios.post(postLightSailOff, payload).then((response) => response.data);
});

export const getStatus = createAsyncThunk('lightSail/getStatus', () =>
  axios.get(getLightSailStatus).then((response) => response.data)
);

const lightSailSlice = createSlice({
  name: 'lightSail',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(postLightSailAction.pending, (state) => {
      state.loading = true;
      state.lightSail = [];
      state.error = null;
    });
    builder.addCase(postLightSailAction.fulfilled, (state, action) => {
      
      state.loading = false;
      state.lightSail = action.payload;
      state.error = null;
    });
    builder.addCase(postLightSailAction.rejected, (state, action) => {
      state.loading = false;
      state.lightSail = [];
      state.error = action.error.message;
    });
    builder.addCase(offLightSailAction.pending, (state) => {
      state.loading = true;
      state.lightSail = [];
      state.error = null;
    });
    builder.addCase(offLightSailAction.fulfilled, (state, action) => {
      
      state.loading = false;
      state.lightSail = action.payload;
      state.error = null;
    });
    builder.addCase(offLightSailAction.rejected, (state, action) => {
      state.loading = false;
      state.lightSail = [];
      state.error = action.error.message;
    });
    builder.addCase(getStatus.pending, (state) => {
      state.loading = true;
      state.status = [];
      state.error = null;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      
      state.loading = false;
      state.status = action.payload;
      state.error = null;
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.loading = false;
      state.status = [];
      state.error = action.error.message;
    });
  },
});

export default lightSailSlice.reducer;
