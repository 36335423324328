import { Auth } from 'aws-amplify';
import React from 'react';
import { useNavigate } from 'react-router';
import { TextField } from '@mui/material';

export default function ConfirmSignUp() {
  const navigate = useNavigate();

  const [username, setUsername] = React.useState(localStorage.getItem('username') || '');
  const [code, setCode] = React.useState('');

  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username, code);
      navigate('/login');
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-bold text-center text-stone-900 uppercase">Confirm Sign Up</h1>
        <form className="mt-6">
          <div className="mb-2">
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              onChange={(e) => setUsername(e.target.value)}
              required
              value={username}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Verification Code"
              name="code"
              onChange={(e) => setCode(e.target.value)}
              required
              value={code}
              variant="outlined"
            />
          </div>

          <div className="mt-6">
            <button
              type="button"
              className="w-full px-4 py-2 tracking-wide text-stone-900 transition-colors duration-200 transform bg-amber-300 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-600"
              onClick={confirmSignUp}
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
