import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Divider, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Tooltip, Typography } from "@mui/material";
import { fToNow } from "../utils/formatTime";
import store from '../redux/store';
import Iconify from "../components/iconify/Iconify";
import { getNotifications, postNotification, postSingleNotification } from "../store/notification.slice";


function Notification() {
  const dispatch = useDispatch();
  const stateElements = store.getState();
  const userId = stateElements.profile.profile.admin_user_id;
  const payload = {
    userId,
    rowsPerPage: 999,
    page: 0,
  };

  useEffect(() => {
    dispatch(getNotifications(payload));
  }, []);

  const { notificationList } = useSelector((state) => state.notifications);
  const NOTIFICATIONS = notificationList?.items;
  const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notifications?.filter((item) => item.is_read === false).length;

  useEffect(() => {
    setNotifications(NOTIFICATIONS);
  }, [NOTIFICATIONS]);
  
  const handleMarkAllAsRead = async () => {
    const res = await dispatch(postNotification(userId));
    dispatch(getNotifications(payload));
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You have {totalUnRead} unread messages
          </Typography>
        </Box>

        {totalUnRead > 0 && (
          <Tooltip title=" Mark all as read">
            <IconButton color="primary" onClick={handleMarkAllAsRead}>
              <Iconify icon="eva:done-all-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <List
        disablePadding
        subheader={
          <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            Latest
          </ListSubheader>
        }
      >
        {notifications?.slice(0, 2).map((notification) => (
          <NotificationItem key={notification.notification_id} notification={notification} />
        ))}
      </List>

      {notifications?.length > 2 && (
        <List
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              Older Notifications
            </ListSubheader>
          }
        >
          {notifications?.map((notification) => (
            <NotificationItem key={notification.notification_id} notification={notification} />
          ))}
        </List>
      )}
    </>
  )
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    is_read: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};
  
function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const stateElements = store.getState();
  const userId = stateElements.profile.profile.admin_user_id;

  const handleSingleRead = async (event, notificationId) => {
    const payload = {
      userId,
      notificationId,
      rowsPerPage: 999,
      page: 0,
    };
    await dispatch(postSingleNotification(payload));
    setOpen(null);
    dispatch(getNotifications(payload));
  };
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.is_read && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={(event) => handleSingleRead(event, notification.notification_id)}
    >
      <ListItemAvatar>
        {notification?.icon_type === 'USER' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/images/avatars/avatar_2.jpg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'APPROVED' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_package.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'REJECTED' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/alert-triangle-fill.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'PROCESSING' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_shipping.svg"
          >
            {avatar}
          </Avatar>
        )}
        {notification?.icon_type === 'PROPERTY' && (
          <Avatar
            sx={{ bgcolor: 'background.neutral', height: '23px', width: '23px' }}
            src="/assets/icons/ic_notification_mail.svg"
          >
            {avatar}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.notification_created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
  
// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <>
      <Typography variant="subtitle2">{notification.title}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.description}
      </Typography>
    </>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}

export default Notification;
