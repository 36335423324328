import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Alert, Box, Button, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../store/profile.slice';
import { signIn } from '../store/sign-in.slice';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userDetails, setUserDetails] = useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isInviteModelOpen, setIsInviteModelOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  let isAdmin = null;

  const handleModalClose = () => setIsInviteModelOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Get the State of the Profile slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState.profile;

  // Handles Password flow
  async function signInFunc() {

    if(username === '') {
      setErrorMessage('Email cannot be empty, please enter your email.');
      setOpen(true);
    } else if(password === '') {
      setErrorMessage('Password cannot be empty, please enter your password.');
      setOpen(true);
    } else {
      try {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserDetails(user);
          setIsInviteModelOpen(true)
        }
        else if (user) {
          const payload = {
            userAttributes: user.attributes,
            isLoggedIn: true,
          };
          dispatch(signIn(payload));
          await Auth.currentAuthenticatedUser()
  // eslint-disable-next-line consistent-return
  .then(async (res) => {
    const userRole = res.signInUserSession?.accessToken.payload['cognito:groups'];
    // eslint-disable-next-line no-unneeded-ternary
    isAdmin = userRole?.includes('Admin') ? true : false;

     if (isAdmin) {
    navigate('/dashboard/app');
    }
     else {
       navigate('/error');
    }


  })
  .catch((e) => {
    console.log(e);
  });
          await dispatch(getProfile(user.attributes.email));
         // navigate('/dashboard/app');
        }
      } catch (error) {
        setErrorMessage('Login failed, please ensure correct email and password is used.')
        setOpen(true);
      }
    }
  }

  // Handles federated sign-in using Google
  /* async function handleGoogleLogin() {
    await Auth.federatedSignIn({ provider: 'Google' });
  } */

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleEnter = (e) => {
    if(e.key === 'Enter')
      signInFunc()
  };
  async function changePassword() {

    if(confirmPassword === '') {
      setErrorMessage('Email cannot be empty, please enter your email.');
      setOpen(true);
    } else if(password === '') {
      setErrorMessage('Password cannot be empty, please enter your password.');
      setOpen(true);
    } else {
      try {
      Auth.completeNewPassword(
        userDetails, // the Cognito User Object
        newPassword, // the new password
      )
        .then(() => {
          // at this time the user is logged in if no MFA required
          handleModalClose();
          Auth.signOut();
        })
        .catch((e) => {
          console.log(e);
        });
      } catch (error) {
        console.log(error)
        setErrorMessage('Oops...Something went wrong!')
        setOpen(true);
      }
    }
  }
  return (
    <>
        <Modal
        open={isInviteModelOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <Box sx={{ color: '#999', cursor: 'pointer' }} onClick={() => handleModalClose()}>
              {' '}
              <Close />
            </Box>
            <h1 className="text-3xl font-bold text-center text-stone-900 ">Change Password</h1>
            <form className="mt-6">
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  type='password'
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  // value={given_name}
                  variant="outlined"
                  value={newPassword}
                />
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="Confirm New Password"
                  name="confirmPassword"
                  type='password'
                   onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  // value={family_name}
                  variant="outlined"
                  value={confirmPassword}
                />
              </div>

              <div className="mt-10">
                <button
                  type="button"
                  className="w-full px-4 py-2 tracking-wide text-stone-900 transition-colors duration-200 transform bg-amber-300 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-300"
                  onClick={() => changePassword()}
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-bold text-center text-stone-900 uppercase">Log in</h1>
        <form className="mt-6">
          <div className="mb-2">
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              onChange={(e) => setUsername(e.target.value)}
              required
              value={username}
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              required
              value={password}
              variant="outlined"
              onKeyDown={handleEnter}
            />
          </div>
          {/* <a href="#" className="text-xs text-lime-600 hover:underline">
            Forgot Password?
          </a> */}
          <div className="mt-6">
            <button type="button" className="buton button--effect w-full px-4 py-2 rounded-md " onClick={signInFunc}>
              Login
            </button>
          </div>
        </form>
        {/* <div className="relative flex items-center justify-center w-full mt-6 border border-t ">
          <div className="absolute px-5 bg-white">Or</div>
        </div>
        <div className="flex mt-8 gap-x-2">
          <button
            type="button"
            className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1"
            onClick={handleGoogleLogin}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="w-5 h-5 fill-current">
              <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z" />
            </svg>
            <p className="pl-4">Sign in with Google</p>
          </button>
        </div>

        <p className="mt-8 text-xs font-light text-center text-gray-700">
          {' '}
          Don't have an account?{' '}
          <a href="/sign-up" className="font-medium text-lime-600 hover:underline">
            Sign up
          </a>
        </p> */}

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

      </div>
      </div>
    </>
  );
}
