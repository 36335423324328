/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Close from '@mui/icons-material/Close';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Switch,
  Modal,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { inviteUser } from '../store/accounts.slice';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { changeRole, changeStatus, getUsers } from '../store/user-mgmt.slice';

import store from '../redux/store';

// modal style

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'first_name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  { id: 'created_at', label: 'Joined Date', alignRight: false },
  { id: 'created_time', label: 'Joined Time', alignRight: false },
  { id: 'User/Admin', label: 'User/Admin', alignRight: false },
  { id: 'account_status', label: 'Account Status', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('date');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [activeAppId, setActiveAppId] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const payload = {
    rowsPerPage,
    page,
    order,
    orderBasedOn: 'created_at',
    searchElement,
  };

  /* Products API GET Call */
  useEffect(() => {
    dispatch(getUsers(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);

  const userState = useSelector((state) => state.users);
  const { usersList } = userState;

  const handleOpenMenu = (event, user_id) => {
    setOpen(event.currentTarget);
    setActiveAppId(user_id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersList?.items?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleEditUserMgmt = (event, userId) => {
    const payload = {
      user_id: userId,
      role_id: event.target.checked === true ? 1 : 2,
    };
    dispatch(changeRole(payload));
  };
  const handleChangeStatus = (event, userId) => {
    const payload = {
      user_id: userId,
    };
    dispatch(changeStatus(payload));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList?.items?.length) : 0;

  const filteredUsers = usersList?.items; // applySortFilter(usersList?.items, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers?.length && !!filterName;

  // -----------------------------------------invite admin User------------------------------------------

  const [isInviteModelOpen, setIsInviteModelOpen] = useState(false);

  const handleModalClose = () => setIsInviteModelOpen(false);

  const validationSchema = yup.object({
    email: yup.string().email().required('Required'),
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
  });

  const IsErrorMethod = (type) => {
    return type in formik.touched && type in formik.errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
    },
    validationSchema,
    onSubmit: (values) => {
      inviteAdmin(values);
      // navigate('/login')
    },
  });

  const [inviteAlert, setInviteAlert] = useState({ show: false, loading: false, message: '', status: '' });

  const inviteAdmin = async (values) => {
    setInviteAlert({ ...inviteAlert, loading: true, show: false });
    const response = await dispatch(inviteUser(values));
    if (response.payload) {
      setInviteAlert({ loading: false, show: true, message: `Invite sent to ${values.email}`, status: 'success' });
    } else if (response.error.message.split(' ')[response.error.message.split(' ').length - 1] === '409') {
      setInviteAlert({ loading: false, show: true, message: 'User already exist', status: 'warning' });
    } else {
      setInviteAlert({ loading: false, show: true, message: 'Failed to invite user try again later', status: 'error' });
    }
    setTimeout(() => {
      setInviteAlert({ loading: false, show: false, message: '', status: '' });
      formik.resetForm();
      handleModalClose();
    }, 3000);
  };

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Modal
        open={isInviteModelOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
          <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <Box sx={{ color: '#999', cursor: 'pointer' }} onClick={() => handleModalClose()}>
              {' '}
              <Close />
            </Box>
            <h1 className="text-3xl font-bold text-center text-stone-900 ">Invite Admin</h1>
            <form className="mt-6">
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  // onChange={(e) => setgiven_name(e.target.value)}
                  required
                  // value={given_name}
                  variant="outlined"
                  value={formik.values.first_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={IsErrorMethod('first_name')}
                  helperText={IsErrorMethod('first_name') && formik.errors.first_name}
                />
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  // onChange={(e) => setfamily_name(e.target.value)}
                  required
                  // value={family_name}
                  variant="outlined"
                  value={formik.values.last_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={IsErrorMethod('last_name')}
                  helperText={IsErrorMethod('last_name') && formik.errors.last_name}
                />
              </div>
              <div className="mb-5">
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  required
                  variant="outlined"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={IsErrorMethod('email')}
                  helperText={IsErrorMethod('email') && formik.errors.email}
                />
              </div>
              {inviteAlert.show && (
                <Alert severity={inviteAlert.status} sx={{ width: '100%' }}>
                  {inviteAlert.message}
                </Alert>
              )}
              <div className="mt-10">
                <button
                  type="button"
                  className="w-full px-4 py-2 tracking-wide text-stone-900 transition-colors duration-200 transform bg-amber-300 rounded-md hover:bg-lime-600 focus:outline-none focus:bg-lime-300"
                  onClick={() => formik.submitForm()}
                  disabled={inviteAlert.loading || inviteAlert.show}
                >
                  {inviteAlert.loading ? <CircularProgress size={'1.5rem'} sx={{ color: '#777' }} /> : 'Invite'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin User Management
          </Typography>
          <Button
            onClick={() => setIsInviteModelOpen(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            className="bg-green-700"
          >
            Invite
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholder="Search Users..."/> */}
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search User name or Email..."
            onChange={(event) => setsearchElement(event.target.value)}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usersList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {usersList?.items?.map((row) => {
                    const {
                      id,
                      email,
                      first_name,
                      last_name,
                      username,
                      image,
                      status,
                      created_at,
                      role,
                      is_admin,
                      admin_user_id,
                      account_status,
                    } = row;
                    const selectedUser = selected.indexOf(username) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                        <TableCell component="th" scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={username} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {first_name} {last_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{status}</TableCell>
                        {/* <TableCell align="left">{role?.role_name}</TableCell> */}
                        <TableCell align="left">{dayjs(created_at).format('DD-MM-YYYY')}</TableCell>
                        <TableCell align="left">{dayjs(created_at).format('hh:mm A')}</TableCell>
                        <TableCell align="left">
                          <FormGroup>
                            {is_admin === true && email !== 'support@havendo.com' && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    defaultChecked
                                    onChange={(event) => handleEditUserMgmt(event, admin_user_id)}
                                  />
                                }
                              />
                            )}
                            {is_admin !== true && email !== 'support@havendo.com' && (
                              <FormControlLabel
                                control={<Switch onChange={(event) => handleEditUserMgmt(event, admin_user_id)} />}
                              />
                            )}
                          </FormGroup>
                        </TableCell>
                        <TableCell align="left">
                          <FormGroup>
                            {account_status === true && email !== 'support@havendo.com' && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    defaultChecked
                                    onChange={(event) => handleChangeStatus(event, admin_user_id)}
                                  />
                                }
                              />
                            )}
                            {account_status !== true && email !== 'support@havendo.com' && (
                              <FormControlLabel
                                control={<Switch onChange={(event) => handleChangeStatus(event, admin_user_id)} />}
                              />
                            )}
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {usersList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
