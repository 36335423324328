/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Popover,
  MenuItem,
  TextField,
  InputAdornment,
  Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// sections
import { UserListHead } from '../sections/@dashboard/user';
import { getProperties, getPropertiesByPropertyId } from '../store/properties.slice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'property_id', label: 'Property Id', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'state', label: 'state', alignRight: false },
  { id: 'country', label: 'country', alignRight: false },
  { id: 'area_size', label: 'Area Size', alignRight: false },
  { id: 'property_tags', label: 'Property Tags', alignRight: false },
  { id: '' },
];

export default function PropertyManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('created_at');

  const [activeAppId, setActiveAppId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const payload = {
    rowsPerPage,
    page,
    order,
    orderBasedon: 'created_at',
    searchElement,
  };
  /* Accounts API GET Call */
  useEffect(() => {
    dispatch(getProperties(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);
  /* Get the State of the Accounts slice */
  const productState = useSelector((state) => state.properties);
  const { propertiesList } = productState;
  const handleOpenMenu = (event, application_id) => {
    setOpen(event.currentTarget);
    setActiveAppId(application_id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = propertiesList?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleNewProduct = (event) => {
    navigate('/dashboard/add-new-property');
  };

  const handleEdit = async (event, product_id) => {
    navigate('/dashboard/edit-property');
    setOpen(null);
    await dispatch(getPropertiesByPropertyId(product_id));
  };

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Properties
          </Typography>
          <button className="buton button--effect" onClick={handleNewProduct}>
            <Iconify icon="eva:plus-fill" />
            Add New
          </button>
        </Stack>
        <Card>
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Property name or Property Id..."
            onChange={(event) => setsearchElement(event.target.value)}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={propertiesList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {propertiesList?.items?.map((row) => {
                    // eslint-disable-next-line camelcase
                    const { property_id, title, state, area_size, price, country, property_tags } = row;
                    const selectedUser = selected.indexOf(title) !== -1;

                    return (
                      <>
                        <TableRow hover key={property_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {property_id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{title}</TableCell>
                          <TableCell align="left">&#8377; {price}</TableCell>

                          <TableCell align="left">{state}</TableCell>

                          <TableCell align="left">{country}</TableCell>
                          <TableCell align="left">{area_size}</TableCell>
                          {property_tags?.length > 0 &&
                            property_tags.map((tag) => <Chip label={tag?.value} className="mt-5" />)}
                          {property_tags === null && <TableCell align="left">{''}</TableCell>}
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, property_id)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <Popover
                          open={Boolean(open)}
                          anchorEl={open}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.25,
                              },
                            },
                          }}
                        >
                          <MenuItem sx={{ color: 'success.main' }} onClick={(event) => handleEdit(event, activeAppId)}>
                            <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                        </Popover>
                      </>
                    );
                  })}
                </TableBody>

                {propertiesList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={propertiesList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {propertiesList?.loading && (
          <div className="m-auto">
            <CircularProgress />
          </div>
        )}
      </Container>
    </>
  );
}
