/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */

import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Link } from 'react-router-dom';
import { propertyImage } from '../store/fileupload.slice';
import { getProfile } from '../store/profile.slice';
import { productExist, resetAddProduct } from '../store/products.slice';
import { editPropertyPost } from '../store/properties.slice';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const EditProperty = () => {
  const dispatch = useDispatch();

  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  // eslint-disable-next-line prefer-const
  const [images, setImages] = useState([]);
  const propertiesState = useSelector((state) => state.properties);
  const { propertyDetails } = propertiesState;
  const [desc, setDesc] = useState(propertyDetails[0]?.description);
  const [uploadError, setUploadError] = useState('');
    const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  /* Form validity status */
  let isFormInvalid = true;

  /* Products API GET Call */
  useEffect(() => {
    dispatch(resetAddProduct());
    // if (profile.length !== 0) dispatch(getProfile(profile?.profile?.email));
  }, []);
  const [values, setValues] = useState({
    title: propertyDetails[0]?.title,
    subtitle: propertyDetails[0]?.subtitle,
    description: propertyDetails[0]?.description,
    state: propertyDetails[0]?.state,
    country: propertyDetails[0]?.country,
    latitude: propertyDetails[0]?.locations?.latitude,
    longitude: propertyDetails[0]?.locations?.longitude,
    nearest_town: propertyDetails[0]?.nearest_town,
    price: propertyDetails[0]?.price,
    area_size: propertyDetails[0]?.area_size,
    tags: propertyDetails[0]?.property_tags !== null ? propertyDetails[0]?.property_tags[0] : [],
    images: propertyDetails[0]?.image_urls !== null ? propertyDetails[0]?.image_urls : [],
    thumbnail: propertyDetails[0]?.thumbnail !== null ? propertyDetails[0]?.thumbnail : '',
    thumbnail_path: propertyDetails[0]?.thumbnail_path !== null ? propertyDetails[0]?.thumbnail_path : '',
  });
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  useEffect(() => {
    setAutoCompleteValue([]);
    for (let i = 0; i < propertyDetails[0]?.property_tags?.length; i++) {
      setAutoCompleteValue((autoCompleteValue) => [propertyDetails[0]?.property_tags[i].value, ...autoCompleteValue]);
    }
  }, [propertyDetails[0]?.property_tags]);
  const [debouncedproductName] = useDebounce(values.title, 1000);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const fileObj = [];
  const fileArray = [];
  useEffect(() => {
    setTagValue([]);
    for (let i = 0; i < autoCompleteValue.length; i++) {
      //  setTagValue(tagValue.concat({ value: autoCompleteValue[i] }));
      setTagValue((tagValue) => [{ value: autoCompleteValue[i] }, ...tagValue]);
    }
  }, [autoCompleteValue]);

  const editProperty = async (event) => {
    const payload = {
      productId: propertyDetails[0]?.property_id,
      payload: {
        title: values.title,
        subtitle: values.subtitle,
        description: desc,
        price: values.price,
        state: values.state,
        country: values.country,
        locations: {
          latitude: values.latitude,
          longitude: values.longitude,
        },
        nearest_town: values.nearest_town,
        property_tags: tagValue,
        area_size: values.area_size,
        image_urls: values.images,
        thumbnail: values.thumbnail_path,
      },
    };
    await dispatch(editPropertyPost(payload));

    setValues({
      title: null,
      subtitle: null,
      description: null,
      price: null,
      state: null,
      country: null,
      latitude: null,
      longitude: null,
      nearest_town: null,
      tags: null,
      thumbnail: '',
    });
    setDesc(null);
    isFormInvalid = true;
    navigate('/dashboard/property-management');
  };

  /* Check if the fields exists and are not empty */
  if (
    values.hasOwnProperty('title') &&
    values.title !== '' &&
    values.hasOwnProperty('subtitle') &&
    values.subtitle !== '' &&
    values.hasOwnProperty('state') &&
    values.state !== '' &&
    values.hasOwnProperty('country') &&
    values.country !== '' &&
    values.price !== '' &&
    values.price !== null &&
    values.area_size !== '' &&
    values.area_size !== null
  ) {
    isFormInvalid = false;
  } else {
    isFormInvalid = true;
  }
  /* Handle error in the Textfields */
  // eslint-disable-next-line consistent-return
  const handleError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
  };

  const handleNameError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
    return false;
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: '',
    onUpdate: ({ editor }) => {
      const json = editor.getHTML();
      setDesc(json);
      //    setValues({
      //   ...values,
      //   description : json
      // });
      // send the content to an API here
    },
  });

  useEffect(() => {
    setDesc(propertyDetails[0]?.description);
    setValues({
      title: propertyDetails[0]?.title,
      subtitle: propertyDetails[0]?.subtitle,
      description: propertyDetails[0]?.description,
      state: propertyDetails[0]?.state,
      country: propertyDetails[0]?.country,
      latitude: propertyDetails[0]?.locations?.latitude,
      longitude: propertyDetails[0]?.locations?.longitude,
      nearest_town: propertyDetails[0]?.nearest_town,
      price: propertyDetails[0]?.price,
      area_size: propertyDetails[0]?.area_size,
      tags: propertyDetails[0]?.property_tags !== null ? propertyDetails[0]?.property_tags[0] : [],
      images: propertyDetails[0]?.image_urls !== null ? propertyDetails[0]?.image_urls : [],
      thumbnail: propertyDetails[0]?.thumbnail !== null ? propertyDetails[0]?.thumbnail : '',
      thumbnail_path: propertyDetails[0]?.thumbnail_path !== null ? propertyDetails[0]?.thumbnail_path : '',
    });
    editor?.commands?.setContent(propertyDetails[0]?.description);
  }, [propertyDetails]);

  /* useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    editor?.commands?.setContent(desc);
  }, [desc]); */
  useEffect(() => {
    const payload = {
      property_title: values.title,
    };
    dispatch(productExist(payload));
  }, [debouncedproductName]);
  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <div>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          strike
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          code
        </Button>
        {/* <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </Button> */}
        {/* <Button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </Button> */}
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          paragraph
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </Button>
        <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          redo
        </Button>
      </div>
    );
  };

  const uploadMultipleFiles = async (e) => {
    const bodyFormData = new FormData();

    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      bodyFormData.append('files', fileObj[0][i]);
    }

    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(propertyImage(payload));
    if (response?.error) {
       setUploadError('Please check the file size or type')
    }
    if (response?.payload?.length > 0) {
      for (let i = 0; i < response.payload.length; i++) {
        // images.push(response.payload[i])
        setImages([...images, response.payload[i]]);
        values.images = Object.assign([], values.images);
        values.images.push({
          image_id: response.payload[i]?.image_id,
          url: response.payload[i]?.image_url,
        });
      }
    }
  };
 const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
   setUploadError('');
    setOpen(false);
  };
  const handleRemoveImage = (event, product) => {
    values.images = [...values.images];
    for (let i = 0; i < values.images.length; i++) {
      if (values.images[i].image_id === product.image_id) {
        values.images.splice(i, 1);
        setValues({ ...values, images: values.images });
      }
    }
  };

  const [thumnailLoading, setThumbnailLoadin] = useState(false);

  const uploadThumbnail = async (e) => {
    setThumbnailLoadin(true);
    const bodyFormData = new FormData();

    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      bodyFormData.append('files', fileObj[0][i]);
    }

    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(propertyImage(payload));
    setThumbnailLoadin(false);
    if (response.payload) {
      setValues({
        ...values,
        thumbnail: response.payload[0]?.image_url,
        thumbnail_path: response.payload[0]?.file_path,
      });
    }
  };

  const numberOnlyTextField = (e) => {
    const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const textOnlyTextField = (e) => {
    const regex = /^[a-zA-Z]|[ ]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Property details
          </Typography>
           {uploadError !== '' && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {uploadError}
              </Alert>
            </Snackbar>
          )}
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} justifyContent="center">
              <form autoComplete="off">
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Avatar
                        src={values?.thumbnail ? values?.thumbnail : ''}
                        sx={{
                          height: 64,
                          mb: 2,
                          width: 64,
                        }}
                      >
                        <CollectionsIcon />
                      </Avatar>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions className="justify-center flex">
                    <Button disabled={thumnailLoading} variant="text" component="label">
                      {thumnailLoading ? <CircularProgress size={'1.5rem'} /> : 'Edit thumbnail'}
                      <input hidden accept="image/*" type="file" onChange={uploadThumbnail} />
                    </Button>
                  </CardActions>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Title"
                          name="title"
                          error={handleNameError('title')}
                          onChange={(event) => handleChange(event, values.title)}
                          required
                          disabled
                          value={values.title}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Subtitle"
                          name="subtitle"
                          error={handleNameError('subtitle')}
                          onChange={(event) => handleChange(event, values.subtitle)}
                          required
                          value={values.subtitle}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Area size"
                          name="area_size"
                          onChange={numberOnlyTextField}
                          required
                          value={values.area_size}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }}
                          fullWidth
                          label="Price"
                          name="price"
                          onChange={numberOnlyTextField}
                          required
                          error={handleError('price')}
                          value={values.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        {/* <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          minRows={3}
                label="Other features"
                name="description"
                onChange={handleChange}
                          required
                          error={handleError('description')}
                value={values.description}
                variant="outlined"
                        /> */}
                        <FormLabel>Description</FormLabel>
                        <div>
                          <>
                            <MenuBar editor={editor} />
                            <EditorContent editor={editor} className="editor-content" />
                          </>
                        </div>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="State"
                          name="state"
                          onChange={textOnlyTextField}
                          required
                          value={values.state}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Country"
                          name="country"
                          onChange={textOnlyTextField}
                          required
                          value={values.country}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Latitude"
                          name="latitude"
                          onChange={(event) => handleChange(event, values.latitude)}
                          type={'number'}
                          value={values.latitude}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Longitude"
                          type={'number'}
                          name="longitude"
                          onChange={handleChange}
                          error={handleError('longitude')}
                          value={values.longitude}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Nearest town"
                          name="nearest_town"
                          onChange={(event) => handleChange(event, values.nearest_town)}
                          value={values.nearest_town}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={[]}
                          value={autoCompleteValue}
                          onChange={(e, newval, reason) => {
                            setAutoCompleteValue(newval);
                            // setAutoCompleteValue(autoCompleteValue.concat(newval));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Tags"
                              placeholder=""
                              onKeyDown={(e) => {
                                if (e.code === 'Enter' && e.target.value) {
                                  // setAutoCompleteValue(autoCompleteValue => [[propertyDetails[0]?.property_tags[i].value],...autoCompleteValue] );
                                  setAutoCompleteValue(autoCompleteValue.concat(e.target.value));
                                }
                              }}
                            />
                          )}
                        />
                        {/* <TextField
                          InputLabelProps={{ shrink: true }}
                fullWidth
                label="Tags"
                          name="tags"
                onChange={(event)=>handleChange(event,values.tags[0]?.value)}
                value={values?.tags?.length >0 ? values.tags[0]?.value : null}
                variant="outlined"
              /> */}
                      </Grid>
                    </Grid>
                    <Container>
                      <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
                        Media images
                      </Typography>

                      {/* {values.images?.length > 0 && <ProductList products={values.images} />} */}
                      {values.images?.length > 0 && (
                        <Grid container spacing={3}>
                          {values.images.map((product) => (
                            <Grid key={product.image_id} item xs={12} sm={6} md={3}>
                              {/* <ShopProductCard product={product} /> */}
                              <Card>
                                <Box sx={{ pt: '100%' }}>
                                  <StyledProductImg alt={'Property image'} src={product?.url || product?.image_url} />
                                  <IconButton
                                    className="absolute right-0 top-0 bg-neutral-400 black"
                                    onClick={(event) => handleRemoveImage(event, product)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                                <Stack spacing={2} sx={{ p: 3 }}>
                                  <Link color="inherit" underline="hover">
                                    {/* <Typography variant="subtitle2" noWrap>
            {name}
          </Typography> */}
                                  </Link>
                                </Stack>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Container>

                    <div className="h-4/5  ">
                      <div className="p-6 space-y-6 h-4/5 ">
                        <div className="flex justify-center items-center w-full">
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col justify-center items-center w-full h-64 bg-slate-100 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-slate-100
                        dark:bg-slate-100 hover:bg-slate-100 dark:border-gray-600 dark:hover:border-gray-500 "
                          >
                            <div className="flex flex-col justify-center items-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="mb-3 w-10 h-10 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop images
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPEG or JPG. Maximum size 4MB. </p>
                            </div>
                            <div className="form-group multi-preview">{fileArray.json}</div>
                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              onChange={uploadMultipleFiles}
                              multiple
                            />
                          </label>{' '}
                          <div className="form-group multi-preview">
                            {(fileArray || []).map((url) => (
                              <img src={url} alt="..." />
                            ))}
                          </div>
                          {/* <button type="button" className="btn btn-danger btn-block" onClick={uploadFiles}>Upload</button> */}
                        </div>
                      </div>
                    </div>
                    {/*
 <div className="form-group multi-preview">
                    {(fileArray || []).map(url => (
                        <img src={url} alt="..." />
                    ))}
                </div>

                <div className="form-group">
                    <input type="file" className="form-control" onChange={uploadMultipleFiles} multiple />
                </div>
                    <button type="button" className="btn btn-danger btn-block" onClick={uploadFiles}>Upload</button>
                     */}
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className="bg-green-700"
                      onClick={editProperty}
                      disabled={isFormInvalid}
                    >
                      Save details
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EditProperty;
