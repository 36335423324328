import { Auth } from 'aws-amplify';
import React from 'react';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';


export default function NotAuthorized() {

  /* Get the State of the Profile slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState.profile;

   const handleLogout = async () => {
    try {
      await Auth.signOut();

      window.location = '/login';
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed top-0 right-0  z-50 w-full h-full bg-opacity-70 bg-gray-700"
      >
        <div className=" p-4 w-full max-w-2xl top-0 bottom-0 absolute right-0 left-0 m-auto h-min">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg h-5/6 shadow">
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 ">Access Restricted</h3>
            </div>
            {/* <!-- Modal body --> */}
            <div className="h-4/5">
              <div className="p-6 space-y-6 h-4/5 ">
                <Typography>Please contact Admin for granting access. Press Ok to logout.</Typography>
              </div>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="flex items-center  py-2 px-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600 h-20">
              <Button variant="contained" onClick={handleLogout} className="rounded-full p-3 px-5 bg-blue-800">
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
