import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  usersList: [],
  error: null,
};

/* Get Products List API Url */
const getUsersListApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/admin-users`;

/* Get Products List */
export const getUsers = createAsyncThunk('products/getUsers', (payload) =>
  axios
    .get(
      `${getUsersListApiUrl}?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${payload.orderBasedOn}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

const changeRoleUrl = `${process.env.REACT_APP_API_BASE_URL}admin/admin-users/`;

/* Post Application review */
export const changeRole = createAsyncThunk('user-management/changeRole', (payload) =>
  axios.post(`${changeRoleUrl + payload.user_id}/roles/change`).then((response) => response.data)
);

/* Post Application review */
export const changeStatus = createAsyncThunk('user-management/changeStatus', (payload) =>
  axios.post(`${changeRoleUrl + payload.user_id}/status`).then((response) => response.data)
);

const usersSlice = createSlice({
  name: 'user-management',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.usersList = [];
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      
      state.loading = false;
      state.usersList = action.payload;
      state.error = null;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.usersList = [];
      state.error = action.error.message;
    });
  },
});

export default usersSlice.reducer;
