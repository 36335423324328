import { CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus, postLightSailAction } from '../store/lightsail.slice';

export default function MarketingManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(postLightSailAction());
    dispatch(getStatus());
  }, []);
  const lightSailState = useSelector((state) => state.lightSail);
  const { status } = lightSailState;
  if (status.status === 'running' && status.url !== null) {
    window.location.replace(status.url);
  }

  return (
    <div className="m-auto justify-center align-middle content-center">
      {status.status !== 'running' && status.status !== 'stopping' && (
        <>
          <CircularProgress />
          <Typography>Lightsail is turning on...</Typography>
        </>
      )}

      {status.status === 'running' && (
        <>
          <Typography>Lightsail is on. You are now being redirected to {status.url}</Typography>
        </>
      )}
    </div>
  );
}
