/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorContent, useEditor } from '@tiptap/react';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Heading from '@tiptap/extension-heading';
import StarterKit from '@tiptap/starter-kit';
import { postEmployeeFile } from '../store/fileupload.slice';
import { getProfile } from '../store/profile.slice';
import { editProductPost, resetEditProduct, resetAddProduct } from '../store/products.slice';
import './tiptap.scss';

const EditProduct = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const  productDetails = useParams();
  //
  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  const productsState = useSelector((state) => state.products);
  const { productDetails, newProduct } = productsState;

  const productImage = useSelector((state) => state.fileUpload);
  const { fileUploadResponse } = productImage;

  const productUpdate = useSelector((state) => state.productUpdate);

  /* Form validity status */
  let isFormInvalid = true;

  const [desc, setDesc] = useState(productDetails[0]?.description);
  /* Products API GET Call */

  const [values, setValues] = useState({
    productName: '',
    price: '',
    isActived: false,
    maturity_year: '',
    interest_rate: '',
    product_image: '',
  });
  useEffect(() => {
    dispatch(resetEditProduct());
    dispatch(resetAddProduct());
  }, [values.productName]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleDescChange = (event) => {
    setDesc(event.target.value);
  };
  const editor = useEditor({
    extensions: [StarterKit, Heading, Paragraph, Text, TextStyle],
    content: `${desc}`,
    onUpdate: ({ editor }) => {
      const json = editor.getHTML();

      setDesc(json);
      //    setValues({
      //   ...values,
      //   description : json
      // });
      // send the content to an API here
    },
  });

  useEffect(() => {
    setDesc(productDetails[0]?.description);
    setValues({
      productName: productDetails[0]?.name || '',
      price: productDetails[0]?.share_price || '',
      isActived: productDetails[0]?.is_active || '',
      maturity_year: productDetails[0]?.maturity_period || '',
      interest_rate: productDetails[0]?.rate_of_dividend || '',
      product_image: productDetails[0]?.image_url || '',
    });
    editor?.commands?.setContent(productDetails[0]?.description);
  }, [productDetails]);

  /* useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    editor?.commands?.setContent(desc);
  }, [desc]); */

  const editProduct = async (event) => {
    const payload = {
      productId: productDetails[0]?.product_id,
      payload: {
        share_price: values.price,
        maturity_period: values.maturity_year,
        rate_of_dividend: values.interest_rate,
        description: desc,
        is_active: values.isActived,
        image_url: values?.product_image,
      },
    };

    await dispatch(editProductPost(payload));
    navigate('/dashboard/product-management');
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  /* Check if the fields exists and are not empty */
  if (
    values.hasOwnProperty('productName') &&
    values.productName !== '' &&
    values.price !== '' &&
    values.price !== null &&
    values.maturity_year !== '' &&
    values.maturity_year !== null &&
    values.interest_rate !== '' &&
    values.interest_rate !== null
  ) {
    isFormInvalid = false;
  }
  /* Handle error in the Textfields */
  // eslint-disable-next-line consistent-return
  const handleError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
    // if (
    //   values[_fieldName] !== null &&
    //   values[_fieldName] !== ''
    // ) {
    //   if (!values[_fieldName].match(/^[a-zA-Z0-9-/_. ]+$/)) {
    //     isFormInvalid = true;
    //     return true;
    //   }
    // }
  };

  // function handle when we change file
  const fileChangedHandler = async (event) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(postEmployeeFile(payload));

    if (response.payload) {
      setValues({ ...values, product_image: response.payload.image_url });
    }
  };

  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <div className="flex flex-row gap-2 flex-wrap">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          strike
        </Button>
        {/* <Button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          code
        </Button> */}
        {/* <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </Button> */}
        {/* <Button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </Button> */}
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          paragraph
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </Button>
        {/* <Button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </Button> */}
        <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          redo
        </Button>
      </div>
    );
  };

  const numberOnlyTextField = (e) => {
    const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Product details
          </Typography>
          {!productUpdate?.loading && productUpdate?.editedProduct?.length > 0 && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
            >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {productUpdate?.editedProduct[0]?.message}
              </Alert>
            </Snackbar>
          )}
          {!productUpdate?.loading && productUpdate?.error && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
            >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {productUpdate?.error}
              </Alert>
            </Snackbar>
          )}
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Avatar
                      src={values?.product_image ? values?.product_image : ''}
                      sx={{
                        height: 64,
                        mb: 2,
                        width: 64,
                      }}
                    >
                      <CollectionsIcon />
                    </Avatar>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions className="justify-center flex">
                  {/* <Button
        color="primary"
        fullWidth
                    variant="text"

      >
        Upload image
                  </Button> */}
                  <Button variant="text" component="label">
                    Upload image
                    <input hidden accept="image/*" multiple type="file" onChange={fileChangedHandler} />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <form autoComplete="off">
                <Card>
                  {/* <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider /> */}
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Name"
                          name="productName"
                          onChange={handleChange}
                          required
                          disabled
                          value={values.productName}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Maturity Year"
                          name="maturity_year"
                          onChange={numberOnlyTextField}
                          required
                          InputLabelProps={{ shrink: true }}
                          value={values.maturity_year}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                          fullWidth
                          label="Rate of Interest"
                          name="interest_rate"
                          onChange={numberOnlyTextField}
                          required
                          value={values.interest_rate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormLabel>Description</FormLabel>
                        <div>
                          {desc && (
                            <>
                              <MenuBar editor={editor} />
                              <EditorContent editor={editor} className="editor-content" />
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }}
                          fullWidth
                          label="Share Price"
                          name="price"
                          onChange={numberOnlyTextField}
                          required
                          error={handleError('price')}
                          value={values.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Is Active?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="isActived"
                          value={values.isActived}
                          onChange={handleChange}
                        >
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className="bg-green-700"
                      onClick={editProduct}
                      disabled={isFormInvalid}
                    >
                      Save details
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {newProduct.length > 0 && productsState?.error == null && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            New item has been added
          </Alert>
        </Snackbar>
      )}
      {productsState?.error != null && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {productsState?.error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default EditProduct;
