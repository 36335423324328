import { createSlice } from '@reduxjs/toolkit';

const initialStateObject = {
  selectedProduct: [],
};

const productSelectionSlice = createSlice({
  name: 'productSelection',
  initialState: initialStateObject,
  reducers: {
    selectProduct: (state, action) => {
      
      state.selectedProduct = action.payload;
    },
  },
});

export default productSelectionSlice.reducer;
export const { selectProduct } = productSelectionSlice.actions;
