/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CollectionsIcon from '@mui/icons-material/Collections';
import { postEmployeeFile } from '../store/fileupload.slice';
import { getProfile } from '../store/profile.slice';
import { addNewProductPost, productExist, resetAddProduct } from '../store/products.slice';

const AddNewProduct = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');
  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  const productsState = useSelector((state) => state.products);
  const { newProduct } = productsState;

  const navigate = useNavigate();

  /* Form validity status */
  let isFormInvalid = true;

  /* Products API GET Call */
  useEffect(() => {
    dispatch(resetAddProduct());
    // if (profile.length !== 0) dispatch(getProfile(profile?.profile?.email));
  }, []);
  const [values, setValues] = useState({
    productName: '',
    description: '',
    price: '',
    isActive: false,
    maturity_year: '',
    interest_rate: '',
  });
  const [debouncedproductName] = useDebounce(values.productName, 1000);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const addNewProduct = async (event) => {
    const payload = {
      name: values.productName,
      maturity_period: values.maturity_year,
      rate_of_dividend: values.interest_rate,
      share_price: values.price,
      description: desc,
      is_active: values.isActive,
      image_url: image,
    };

    await dispatch(addNewProductPost(payload));

    setValues({
      productName: '',
      description: '',
      price: '',
      isActive: false,
    });
    setDesc(null);
    isFormInvalid = true;
    navigate('/dashboard/product-management');
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  /* Check if the fields exists and are not empty */
  if (
    values.hasOwnProperty('productName') &&
    values.productName !== '' &&
    values.hasOwnProperty('description') &&
    values.price !== '' &&
    values.price !== null &&
    values.maturity_year !== '' &&
    values.maturity_year !== null &&
    values.interest_rate !== '' &&
    values.interest_rate !== null
  ) {
    isFormInvalid = false;
  } else {
    isFormInvalid = true;
  }
  /* Handle error in the Textfields */
  // eslint-disable-next-line consistent-return
  const handleError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
    return false;
  };
  const isproductExist = useSelector((state) => state.products);
  /* Handle the helper text in the Textfields */
  const handleText = (_fieldName, _helperText) => {
    if (isproductExist?.existError !== null) {
      isFormInvalid = true;
      return _helperText;
    }
    return null;
  };

  const handleNameError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
    if (isproductExist?.existError !== null) {
      return true;
    }
    return false;
  };

  // function handle when we change file
  const fileChangedHandler = async (event) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', event.target.files[0]);

    // data for file upload API
    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(postEmployeeFile(payload));

    if (response?.payload?.image_url) setImage(response?.payload?.image_url);
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: '',
    onUpdate: ({ editor }) => {
      const json = editor.getHTML();
      setDesc(json);
    },
  });
  /* useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    editor?.commands?.setContent(desc);
  }, [desc]); */
  useEffect(() => {
    const payload = {
      product_name: values.productName,
    };
    dispatch(productExist(payload));
  }, [debouncedproductName]);
  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <div>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          strike
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          code
        </Button>
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          paragraph
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </Button>
        <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          redo
        </Button>
      </div>
    );
  };

  const numberOnlyTextField = (e) => {
    const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Product details
          </Typography>
          {newProduct.length > 0 && productsState?.error == null && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                New item has been added
              </Alert>
            </Snackbar>
          )}
          {productsState?.error != null && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {productsState?.error}
              </Alert>
            </Snackbar>
          )}
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Avatar
                      src={image}
                      sx={{
                        height: 64,
                        mb: 2,
                        width: 64,
                      }}
                    >
                      <CollectionsIcon />
                    </Avatar>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions className="justify-center flex">
                  <Button variant="text" component="label">
                    Upload image
                    <input hidden accept="image/*" multiple type="file" onChange={fileChangedHandler} />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <form autoComplete="off">
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Name"
                          name="productName"
                          onChange={(event) => handleChange(event, values.productName)}
                          required
                          helperText={handleText('productName', 'The product name already exists!')}
                          value={values.productName}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Maturity Year"
                          name="maturity_year"
                          onChange={numberOnlyTextField}
                          required
                          value={values.maturity_year}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                          fullWidth
                          label="Rate of Interest"
                          name="interest_rate"
                          onChange={numberOnlyTextField}
                          required
                          value={values.interest_rate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormLabel>Description</FormLabel>
                        <div>
                          <>
                            <MenuBar editor={editor} />
                            <EditorContent editor={editor} className="editor-content" />
                          </>
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }}
                          fullWidth
                          label="Share Price"
                          name="price"
                          onChange={numberOnlyTextField}
                          required
                          value={values.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Is Active?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="isActive"
                          value={values.isActive}
                          onChange={handleChange}
                        >
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className="bg-green-700"
                      onClick={() => addNewProduct()}
                      disabled={isFormInvalid}
                    >
                      Save details
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddNewProduct;
