import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  applicationReview: [],
  error: null,
};

/* Post Application review API Url */
const postApplicationApiUrl = `${process.env.REACT_APP_API_BASE_URL}application/user/`;

/* Post Application review */
export const postApplication = createAsyncThunk('products/postApplication', (payload) => 
  axios
    .post(`${postApplicationApiUrl + payload.userId}/application`, payload.payload)
    .then((response) => response.data)
);

const applicationReviewSlice = createSlice({
  name: 'applicationReview',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(postApplication.pending, (state) => {
      state.loading = true;
      state.applicationReview = [];
      state.error = null;
    });
    builder.addCase(postApplication.fulfilled, (state, action) => {
      
      state.loading = false;
      state.applicationReview = action.payload;
      state.error = null;
    });
    builder.addCase(postApplication.rejected, (state, action) => {
      state.loading = false;
      state.applicationReview = [];
      state.error = action.error.message;
    });
  },
});

export default applicationReviewSlice.reducer;
