/* eslint-disable camelcase */
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const {  url, price, colors, status, priceSale,image_url } = product;

  return (
    <Card>
      <Box sx={{ pt: '100%' }}>
        <StyledProductImg alt={'Property image'} src={url || image_url} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          {/* <Typography variant="subtitle2" noWrap>
            {name}
          </Typography> */}
        </Link>
      </Stack>
    </Card>
  );
}
