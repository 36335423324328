import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import AccountsPage from './pages/AccountsPage';
import ProductManagement from './pages/product-management';
import AddNewProduct from './pages/add-new-product';
import LoginPage from './pages/LoginPage';
import SignUp from './pages/sign-up';
import ConfirmSignUp from './confirm-sign-up';
import { RouteAuthentication } from './components/route-authentication/route-authentication.auth';
import EditProduct from './pages/edit-product';
import ApprovalsPage from './pages/ApprovalsPage';
import PropertyManagement from './pages/property-management';
import AddNewProperty from './pages/add-new-property';
import MarketingManagement from './pages/MarketingContent';
import EditProperty from './pages/edit-property';
import Notification from './pages/notification'
import NotAuthorized from './pages/not-authorized';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <RouteAuthentication><DashboardAppPage /> </RouteAuthentication> },
        { path: 'approvals', element: <RouteAuthentication><ApprovalsPage /> </RouteAuthentication> },
        { path: 'account', element: <RouteAuthentication><AccountsPage /> </RouteAuthentication> },
        { path: 'product-management', element: <RouteAuthentication><ProductManagement /></RouteAuthentication> },
        { path: 'property-management', element: <RouteAuthentication><PropertyManagement /></RouteAuthentication> },
        { path: 'add-new-product', element: <RouteAuthentication><AddNewProduct /> </RouteAuthentication> },
        { path: 'add-new-property', element: <RouteAuthentication><AddNewProperty /> </RouteAuthentication> },
        { path: 'edit-product', element: <RouteAuthentication><EditProduct /> </RouteAuthentication> },
        { path: 'edit-property', element: <RouteAuthentication><EditProperty /> </RouteAuthentication> },
        { path: 'marketing-content-management', element: <RouteAuthentication><MarketingManagement /> </RouteAuthentication> },
        { path: 'property-management' },
        { path: 'settings', element: <RouteAuthentication><UserPage /></RouteAuthentication> },
        { path: 'notification', element: <RouteAuthentication><Notification /></RouteAuthentication> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'sign-up', element: <SignUp /> },
        { path: 'confirm-sign-up', element: <ConfirmSignUp /> },
        { path: 'error', element: <NotAuthorized /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
