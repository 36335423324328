import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  productList: [],
  newProduct: [],
  productDetails: [],
  editedProduct: [],
  deletedProduct: [],
  isproductExist: false,
  error: null,
  existError: null,
};

/* Get Products List API Url */
const getProductListApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/product-management/products`;

/* Get Products List */
export const getProducts = createAsyncThunk('products/fetchProductList', (payload) =>
  axios
    .get(
      `${getProductListApiUrl}?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${payload.orderBasedon}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

/* Post Application review API Url */
const addNewProductApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/product-management/products`;

const productExistUrl = `${process.env.REACT_APP_API_BASE_URL}admin/product-management/products/product-exist`;

/* Post Application review */
export const addNewProductPost = createAsyncThunk('products/addNewProduct', (payload) => {
  return axios.post(addNewProductApiUrl, payload).then((response) => response.data);
});

/* Edit Product */
export const editProductPost = createAsyncThunk('products/editProductPost', (payload) => {
  return axios.patch(`${addNewProductApiUrl}/${payload.productId}`, payload.payload).then((response) => response.data);
});
/* Get Products List */
export const getProductByProductId = createAsyncThunk('products/fetchProduct', (productId) =>
  axios.get(`${getProductListApiUrl}/${productId}`).then((response) => response.data)
);

/* Get productExist */
export const productExist = createAsyncThunk('products/productExist', (productName) =>
  axios.post(`${productExistUrl}`, productName).then((response) => response.data)
);

/* Delete Product */
export const deleteProduct = createAsyncThunk('products/fetchProduct', (productId) =>
  axios.delete(`${getProductListApiUrl}/${productId}`).then((response) => response.data)
);

const productSlice = createSlice({
  name: 'products',
  initialState: initialStateObject,
  reducers: {
    resetEditProduct: (state) => {
      state.loading = false;
      state.editedProduct = [];
      state.error = null;
    },
    resetAddProduct: (state) => {
      state.loading = false;
      state.newProduct = [];
      state.error = null;
    },
    resetDeleteProduct: (state) => {
      state.loading = false;
      state.deletedProduct = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
      state.productList = [];
      state.error = null;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.productList = action.payload;
      state.error = null;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.loading = false;
      state.productList = [];
      state.error = action.error.message;
    });
    builder.addCase(addNewProductPost.pending, (state) => {
      state.loading = true;
      state.newProduct = [];
      state.error = null;
    });
    builder.addCase(addNewProductPost.fulfilled, (state, action) => {
      state.loading = false;
      state.newProduct = [action.payload];
      state.error = null;
    });
    builder.addCase(addNewProductPost.rejected, (state, action) => {
      state.loading = false;
      state.newProduct = [];
      state.error = action.error.message;
    });
    builder.addCase(productExist.pending, (state) => {
      state.loading = true;
      state.isproductExist = false;
      state.existError = null;
    });
    builder.addCase(productExist.fulfilled, (state, action) => {
      state.loading = false;
      state.newProduct = action.payload !== null;
      state.existError = null;
    });
    builder.addCase(productExist.rejected, (state, action) => {
      state.loading = false;
      state.newProduct = false;
      state.existError = action.error.message;
    });
    builder.addCase(getProductByProductId.pending, (state) => {
      state.loading = true;
      state.productDetails = [];
      state.error = null;
    });
    builder.addCase(getProductByProductId.fulfilled, (state, action) => {
      state.loading = false;
      state.productDetails = [action.payload];
      state.error = null;
    });
    builder.addCase(getProductByProductId.rejected, (state, action) => {
      state.loading = false;
      state.productDetails = [];
      state.error = action.error.message;
    });
    builder.addCase(editProductPost.pending, (state) => {
      state.loading = true;
      state.editedProduct = [];
      state.error = null;
    });
    builder.addCase(editProductPost.fulfilled, (state, action) => {
      state.loading = false;
      state.editedProduct = [action.payload];
      state.error = null;
    });
    builder.addCase(editProductPost.rejected, (state, action) => {
      state.loading = false;
      state.editedProduct = [];
      state.error = action.error.message;
    });
  },
});

export default productSlice.reducer;
export const { resetEditProduct, resetDeleteProduct, resetAddProduct } = productSlice.actions;
