import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  propertiesList: [],
  propertyDetails: [],
  newProperty: [],
  isproductExist: false,
  error: null,
};

/* Get Products List API Url */
const getPropertiesApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/properties`;

/* Get Products List */
export const getProperties = createAsyncThunk('products/getProperties', (payload) =>
  axios
    .get(
      `${getPropertiesApiUrl}?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${payload.orderBasedon}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);
/* Edit List */
export const getPropertiesByPropertyId = createAsyncThunk('properties/getPropertiesByPropertyId', (productId) =>
  axios.get(`${getPropertiesApiUrl}/${productId}`).then((response) => response.data)
);

/* Post Application review API Url */
const addNewPropertyApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/properties`;

const propertiesExistUrl = `${process.env.REACT_APP_API_BASE_URL}admin/properties/property-exist`;

/* Get propertyExist */
export const propertyExist = createAsyncThunk('property/propertyExist', (productName) =>
  axios.post(`${propertiesExistUrl}`, productName).then((response) => response.data)
);

/* Post Application review */
export const addNewPropertyPost = createAsyncThunk('products/addnewProperty', (payload) => 
  axios.post(addNewPropertyApiUrl, payload).then((response) => response.data)
);

/* Edit Product */
export const editPropertyPost = createAsyncThunk('products/editPropertyPost', (payload) => 
  axios.patch(`${addNewPropertyApiUrl}/${payload.productId}`, payload.payload).then((response) => response.data)
);

const productSlice = createSlice({
  name: 'properties',
  initialState: initialStateObject,
  reducers: {
    resetEditProduct: (state) => {
      state.loading = false;
      state.propertiesList = [];
      state.error = null;
    },
    resetAddProduct: (state) => {
      state.loading = false;
      state.propertiesList = [];
      state.error = null;
    },
    resetDeleteProduct: (state) => {
      state.loading = false;
      state.propertiesList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProperties.pending, (state) => {
      state.loading = true;
      state.propertiesList = [];
      state.error = null;
    });
    builder.addCase(getProperties.fulfilled, (state, action) => {
      
      state.loading = false;
      state.propertiesList = action.payload;
      state.error = null;
    });
    builder.addCase(getProperties.rejected, (state, action) => {
      state.loading = false;
      state.propertiesList = [];
      state.error = action.error.message;
    });
    builder.addCase(propertyExist.pending, (state) => {
      state.loading = true;
      state.isproductExist = false;
      state.existError = null;
    });
    builder.addCase(propertyExist.fulfilled, (state, action) => {
      
      state.loading = false;
      state.isproductExist = action.payload !== null;
      state.existError = null;
    });
    builder.addCase(propertyExist.rejected, (state, action) => {
      
      state.loading = false;
      state.isproductExist = false;
      state.existError = action.error.message;
    });
    builder.addCase(addNewPropertyPost.pending, (state) => {
      state.loading = true;
      state.propertiesList = [];
      state.error = null;
    });
    builder.addCase(addNewPropertyPost.fulfilled, (state, action) => {
      
      state.loading = false;
      state.propertiesList = [action.payload];
      state.error = null;
    });
    builder.addCase(addNewPropertyPost.rejected, (state, action) => {
      state.loading = false;
      state.propertiesList = [];
      state.error = action.error.message;
    });
    builder.addCase(getPropertiesByPropertyId.pending, (state) => {
      state.loading = true;
      state.propertyDetails = [];
      state.error = null;
    });
    builder.addCase(getPropertiesByPropertyId.fulfilled, (state, action) => {
      
      state.loading = false;
      state.propertyDetails = [action.payload];
      state.error = null;
    });
    builder.addCase(getPropertiesByPropertyId.rejected, (state, action) => {
      state.loading = false;
      state.propertyDetails = [];
      state.error = action.error.message;
    });
  },
});

export default productSlice.reducer;
export const { resetEditProduct, resetDeleteProduct, resetAddProduct } = productSlice.actions;
