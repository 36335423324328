/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Popover,
  MenuItem,
  TextField,
  InputAdornment,
  FormGroup,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
// components
import { editProductPost, getProductByProductId, getProducts } from '../store/products.slice';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// sections
import { UserListHead } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_id', label: 'Product Id', alignRight: false },
  { id: 'name', label: 'Product Name', alignRight: false },
  { id: 'share_price', label: 'Face Value', alignRight: false },
  { id: 'created_by', label: 'Created By', alignRight: false },
  { id: 'created_date', label: 'Created Date', alignRight: false },
  { id: 'created_time', label: 'Created Time', alignRight: false },
  { id: 'is_active', label: 'Status', alignRight: false },
  { id: '' },
];

export default function ProductManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('created_at');

  const [activeAppId, setActiveAppId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const payload = {
    rowsPerPage,
    page,
    order,
    orderBasedon: 'created_at',
    searchElement,
  };
  /* Accounts API GET Call */
  useEffect(() => {
    dispatch(getProducts(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);
  /* Get the State of the Accounts slice */
  const productState = useSelector((state) => state.products);
  const { productList } = productState;

  const handleOpenMenu = (event, application_id) => {
    setOpen(event.currentTarget);
    setActiveAppId(application_id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = productList?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleNewProduct = (event) => {
    navigate('/dashboard/add-new-product');
  };

  const handleEdit = async (event, product_id) => {
    navigate('/dashboard/edit-product');
    setOpen(null);
    await dispatch(getProductByProductId(product_id));
  };

  const onSwitchChange = async (event, id, productDetails) => {
    const payload = {
      productId: id,
      payload: {
        share_price: productDetails.share_price,
        maturity_period: productDetails.maturity_period,
        rate_of_dividend: productDetails.rate_of_dividend,
        description: productDetails.description,
        is_active: event.target.checked,
        image_url: productDetails?.image_url,
      },
    };
    dispatch(editProductPost(payload));
  };

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <button className="buton button--effect" onClick={handleNewProduct}>
            <Iconify icon="eva:plus-fill" />
            Add New
          </button>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholder={'Search products...'} /> */}
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Product name or product Id..."
            onChange={(event) => setsearchElement(event.target.value)}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={productList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {productList?.items?.map((row) => {
                    // eslint-disable-next-line camelcase
                    const { product_id, name, created_by, is_active, share_price, avatarUrl, created_at } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <>
                        <TableRow hover key={product_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {product_id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">&#8377; {share_price}</TableCell>

                          <TableCell align="left">{created_by}</TableCell>

                          <TableCell align="left">{dayjs(created_at).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{dayjs(created_at).format('hh:mm A')}</TableCell>

                          <TableCell align="left">
                            <FormGroup>
                              {is_active === true && (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      defaultChecked
                                      onChange={(event) => onSwitchChange(event, product_id, row)}
                                    />
                                  }
                                />
                              )}
                              {is_active === false && (
                                <FormControlLabel
                                  control={<Switch onChange={(event) => onSwitchChange(event, product_id, row)} />}
                                />
                              )}
                            </FormGroup>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, product_id)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <Popover
                          open={Boolean(open)}
                          anchorEl={open}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                              },
                            },
                          }}
                        >
                          <MenuItem sx={{ color: 'success.main' }} onClick={(event) => handleEdit(event, activeAppId)}>
                            <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                        </Popover>
                      </>
                    );
                  })}
                </TableBody>

                {productList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {productList?.loading && (
          <div className="m-auto">
            <CircularProgress />
          </div>
        )}
      </Container>
    </>
  );
}
