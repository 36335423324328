/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Popover,
  MenuItem,
  TextField,
  InputAdornment,
  Alert,
  Snackbar,
  CircularProgress,
  Box,
  Divider,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import productPlaceholder from '../assets/product-placeholder.jpg';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import {
  approveApplication,
  bulkApproveApplication,
  fetchSinlgeApplication,
  getAccounts,
  rejectApplication,
} from '../store/accounts.slice';
import ApprovalHead from '../sections/@dashboard/user/ApprovalHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'application_id', label: 'Application Id', alignRight: false },
  { id: 'username', label: 'User Name', alignRight: false },
  { id: 'product_name', label: 'Product Name', alignRight: false },
  { id: 'no_of_shares', label: 'Number of Shares', alignRight: false },
  { id: 'payment_amount', label: 'Amount', alignRight: false },
  { id: 'date_of_purchase', label: 'Date of Purchase', alignRight: false },
  { id: 'time_of_purchase', label: 'Time of Purchase', alignRight: false },
  { id: 'payment_method_type', label: 'Mode of Payment', alignRight: false },
  { id: '' },
];

export default function ApprovalsPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('date_of_purchase');

  const [activeAppId, setActiveAppId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const [applicationData, setApplicationData] = useState(null);

  const [approval, setApproval] = useState({ approved: false, rejected: false });

  const dispatch = useDispatch();

  const payload = {
    type: 'PENDING',
    rowsPerPage,
    page,
    order,
    orderBasedOn: orderBy,
    searchElement,
  };

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  /* Accounts API GET Call */
  useEffect(() => {
    dispatch(getAccounts(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);

  /* Get the State of the Accounts slice */
  const accountState = useSelector((state) => state.accounts);

  const { accountList, singleApplication } = accountState;

  const handleOpenMenu = (event, applicationId) => {
    setOpen(event.currentTarget);
    setActiveAppId(applicationId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accountList?.items?.map((n) => n.application_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleApprove = async (event, appDetails) => {
    setOpen(null);
    await dispatch(approveApplication(appDetails));
    setApproval({ approved: true, rejected: false });
    setTimeout(() => {
      dispatch(getAccounts(payload));
    }, 500);
  };

  const handleBulkApprove = async (event) => {
    const ids = {
      application_ids: selected,
    };
    setOpen(null);
    await dispatch(bulkApproveApplication(ids));
    setTimeout(() => {
      dispatch(getAccounts(payload));
    }, 500);
  };

  const handleReject = async (event, appDetails) => {
    setOpen(null);
    await dispatch(rejectApplication(appDetails));
    setApproval({ approved: false, rejected: true });
    dispatch(getAccounts(payload));
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [applicationResponse, setApplicationResponse] = useState({
    status: 'success',
    loading: false,
    show: false,
    message: 'success',
  });

  const fetchApplicationData = async (application_id) => {
    setApplicationResponse({ ...applicationResponse, loading: true });
    const response = await dispatch(fetchSinlgeApplication(application_id));
    setApplicationResponse({ ...applicationResponse, loading: false });
  };

  if (applicationData) {
    return (
      <>
        <Typography
          onClick={() => {
            setApproval({ approved: false, rejected: false });
            setApplicationData(null);
          }}
          sx={{ fontSize: '1rem', mb: 4, cursor: 'pointer', pl: 4 }}
        >
          <ArrowBackIcon size={'small'} /> Go back
        </Typography>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Application
            </Typography>
            <Box>
              <Button
                onClick={(e) => handleReject(e, singleApplication.application_id)}
                variant="outlined"
                sx={{ borderColor: '#fcd34d', color: 'black', mr: 1, py: 3.28 }}
                size="large"
                disabled={approval.rejected || approval.approved}
              >
                {approval.rejected ? (
                  <>
                    Rejected <CloseIcon />
                  </>
                ) : (
                  <>Reject</>
                )}
              </Button>
              <button
                className="buton button--effect"
                disabled={approval.rejected || approval.approved}
                onClick={(e) => {
                  handleApprove(e, singleApplication.application_id);
                }}
              >
                {approval.approved ? (
                  <>
                    Approved <CheckCircleOutlineIcon />
                  </>
                ) : (
                  <>Approve</>
                )}
              </button>
            </Box>
          </Stack>
          {applicationResponse.loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '5%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box border={1} sx={{ p: 4, borderColor: '#ccc' }}>
              {singleApplication?.product && (
                <>
                  <Typography variant="h4" gutterBottom>
                    {singleApplication.product.name}
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                    <Grid item xs={12} md={4}>
                      <img
                        src={`${singleApplication.product.image_url}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = productPlaceholder;
                        }}
                        alt={'.'}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <section dangerouslySetInnerHTML={{ __html: singleApplication.product.description }} />
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )}
              <Box sx={{ pt: 4 }}>
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem', display: 'flex' }}
                  component="span"
                  gutterBottom
                >
                  Payment
                </Typography>
                {singleApplication?.payment && (
                  <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                        component="span"
                        gutterBottom
                      >
                        Mode :{' '}
                        <span>
                          <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                            {' '}
                            {singleApplication.payment.payment_method_type || 'nil'}
                          </Typography>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                        component="span"
                        gutterBottom
                      >
                        Payment Date
                        <span>
                          <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                            {' : '}
                            {singleApplication.payment.payment_date_received || 'nil'}
                          </Typography>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                        component="span"
                        gutterBottom
                      >
                        Shares
                        <span>
                          <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                            {' : \t'}
                            {singleApplication.no_of_shares || 'nil'}
                          </Typography>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                        component="span"
                        gutterBottom
                      >
                        Share price
                        <span>
                          <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                            {' : '}
                            {singleApplication.share_price || 'nil'}
                            {/* ' ₹' */}
                          </Typography>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                        component="span"
                        gutterBottom
                      >
                        Total price
                        <span>
                          <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                            {' : '}
                            {singleApplication.total_price || 'nil'}
                            {/* ' ₹' */}
                          </Typography>
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Divider sx={{ py: 1 }} />
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem', display: 'flex' }}
                  component="span"
                  gutterBottom
                >
                  Applicants
                </Typography>

                {singleApplication?.applicants &&
                  singleApplication?.applicants.map((val) => (
                    <Box key={val.application_id}>
                      <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            Name :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {val.applicant.name || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            Spouse/Parent Name :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {` ${val.applicant.relationship_name}` || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            PAN :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {val.applicant.PAN || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                        {val.applicant.documents
                          .filter((fil) => fil.document_id)
                          .map((item) => (
                            <Grid key={`${baseUrl}${item.url}`} item xs={12} md={4}>
                              <Typography
                                key={item.document_id}
                                sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                                component="span"
                                gutterBottom
                              >
                                {item.document_type}
                                {' :'}
                              </Typography>

                              <Box sx={{ mx: 'auto', my: 2 }}>
                                <a href={item.url} download={item.url} rel="noopener noreferrer" target="_blank">
                                  <Button variant="outlined" sx={{ textTransform: 'none' }}>
                                    View Document
                                  </Button>
                                </a>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      <Divider sx={{ pt: 2 }} />
                    </Box>
                  ))}
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem', display: 'flex' }}
                  component="span"
                  gutterBottom
                >
                  Nominees
                </Typography>
                {singleApplication?.nominees &&
                  singleApplication?.nominees.map((val) => (
                    <Box key={val.application_id}>
                      <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            Name :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {val.nominee.name || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            Spouse/Parent Name :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {` ${val.nominee.relationship_name}` || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                            component="span"
                            gutterBottom
                          >
                            PAN :{' '}
                            <span>
                              <Typography sx={{ fontWeight: '500', fontSize: '1rem' }} gutterBottom>
                                {' '}
                                {val.nominee.PAN || 'nil'}
                              </Typography>
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mt: 2 }} columns={12}>
                        {val.nominee.documents
                          .filter((fil) => fil.document_id)
                          .map((item) => (
                            <Grid key={item.url} item xs={12} md={4}>
                              <Typography
                                key={item.document_id}
                                sx={{ fontWeight: 'bold', fontSize: '1rem', display: 'flex' }}
                                component="span"
                                gutterBottom
                              >
                                {item.document_type}
                                {' :'}
                              </Typography>

                              <Box sx={{ mx: 'auto', my: 2 }}>
                                <a href={item.url} download={item.url} rel="noopener noreferrer" target="_blank">
                                  <Button variant="outlined" sx={{ textTransform: 'none' }}>
                                    View Document
                                  </Button>
                                </a>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      <Divider sx={{ pt: 2 }} />
                    </Box>
                  ))}
              </Box>
            </Box>
          )}
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Investments
          </Typography>
        </Stack>
        {/* <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {'sdfsd'}
          </Alert>
        </Snackbar> */}
        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholder="Search Investments..."/> */}
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Product name or User name..."
            onChange={(event) => setsearchElement(event.target.value)}
          />
          {selected.length > 0 && (
            <Button onClick={handleBulkApprove} className="rounded-full p-3 px-5 mt-6">
              Approve Selection
            </Button>
          )}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ApprovalHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={accountList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {accountList?.items?.map((row) => {
                    // eslint-disable-next-line camelcase
                    const {
                      application_id,
                      product_name,
                      total_price,
                      last_name,
                      no_of_shares,
                      first_name,
                      date_of_purchase,
                      payment_method_type,
                      application_status,
                    } = row;

                    const selectedUser = selected.indexOf(application_id) !== -1;
                    return (
                      <>
                        <TableRow hover key={application_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, application_id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}

                              <Typography variant="subtitle2" noWrap>
                                <TableCell align="left">{application_id}</TableCell>
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {first_name} {last_name}
                          </TableCell>
                          <TableCell align="left">{product_name}</TableCell>
                          <TableCell align="left">{no_of_shares}</TableCell>

                          <TableCell align="left">{total_price}</TableCell>

                          <TableCell align="left">{dayjs(date_of_purchase).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{dayjs(date_of_purchase).format('hh:mm A')}</TableCell>
                          <TableCell align="left">{payment_method_type}</TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, application_id)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                            <Popover
                              open={Boolean(open)}
                              anchorEl={open}
                              onClose={handleCloseMenu}
                              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 200,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                sx={{ color: 'warning.main' }}
                                onClick={() => {
                                  setOpen(null);
                                  setApplicationData(row);
                                  fetchApplicationData(activeAppId);
                                }}
                              >
                                <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                                Check Investment
                              </MenuItem>
                              <MenuItem
                                sx={{ color: 'success.main' }}
                                onClick={(event) => handleApprove(event, activeAppId)}
                              >
                                <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                                Approve
                              </MenuItem>

                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={(event) => handleReject(event, activeAppId)}
                              >
                                <Iconify icon={'eva:alert-triangle-fill'} sx={{ mr: 2 }} />
                                Reject
                              </MenuItem>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>

                {accountList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accountList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
