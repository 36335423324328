import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  profile: [],
  error: null,
  applicationCount: {},
};

/* Get Profile List API Url */
const getProfileApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/admin-users/email/`;

const patchProfileApiUrl = `${process.env.REACT_APP_API_BASE_URL}user-management/users/email/`;

const applicationCountUrl = `${process.env.REACT_APP_API_BASE_URL}admin/product-management/products/applications-count`;

/* Get Profile List */
export const getProfile = createAsyncThunk('profile/fetchProfile', (email) =>
  axios.get(`${getProfileApiUrl + email}/user-profile`).then((response) => response.data)
);

/* Get Application count */
export const getApplicationCount = createAsyncThunk('profile/getApplicationCount', (payload) =>
  axios
    .get(
      `${applicationCountUrl}?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${payload.orderBasedon}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

/* Post Application review */
export const patchProfile = createAsyncThunk('products/patchProfile', (payload) => 
  axios
    .patch(`${patchProfileApiUrl + payload.email}/user-profile`, payload.payload)
    .then((response) => response.data)
);

const productSlice = createSlice({
  name: 'profile',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.profile = [];
      state.error = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = null;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      state.profile = [];
      state.error = action.error.message;
    });
    builder.addCase(getApplicationCount.pending, (state) => {
      state.loading = true;
      state.applicationCount = {};
      state.error = null;
    });
    builder.addCase(getApplicationCount.fulfilled, (state, action) => {
      state.loading = false;
      state.applicationCount = action.payload;
      state.error = null;
    });
    builder.addCase(getApplicationCount.rejected, (state, action) => {
      state.loading = false;
      state.applicationCount = {};
      state.error = action.error.message;
    });
  },
});

export default productSlice.reducer;
