import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* initial State Object */
const initialStateObject = {
  loading: true,
  accountList: [],
  approvalStatus: [],
  singleApplication: null,
  userInvite: null,
  error: null,
  approveError: null,
};

/* Get Accounts List API Url */
const getAccountApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/applications/status/`;

// Single application fetch using application id
const fetchSinlgeApplicationUrl = `${process.env.REACT_APP_API_BASE_URL}admin/applications/`;

const getActivitiesApiUrl = `${process.env.REACT_APP_API_BASE_URL}activity-management/users/`;

const approveApplicationUrl = `${process.env.REACT_APP_API_BASE_URL}admin/applications/`;

const bulkApprovalUrl = `${process.env.REACT_APP_API_BASE_URL}admin/applications/bulk-approval`;

const inviteUrl = `${process.env.REACT_APP_API_BASE_URL}admin/admin-users/invites`;

/* Get Accounts List */
export const getAccounts = createAsyncThunk('accounts/getAccounts', (payload) =>
  axios
    .get(
      `${getAccountApiUrl}${payload.type}?limit=${payload.rowsPerPage}&page=${payload.page}&sort_by=${payload.orderBasedOn}:${payload.order}&search=${payload.searchElement}`
    )
    .then((response) => response.data)
);

export const getActivities = createAsyncThunk('accounts/getActivities', (userId) =>
  axios.get(`${getActivitiesApiUrl + userId}/activities`).then((response) => response.data)
);

export const fetchSinlgeApplication = createAsyncThunk('accounts/getSingleApplication', (applicationId) =>
  axios.get(`${fetchSinlgeApplicationUrl + applicationId}`).then((response) => response.data)
);

/* Post Invite User  */
export const inviteUser = createAsyncThunk('accounts/inviteUser', (payload) => 
  axios.post(`${inviteUrl}`, payload).then((response) => response.data)
);
/* Post Application review */
export const approveApplication = createAsyncThunk('products/approveApplication', (payload) => 
  axios.post(`${approveApplicationUrl + payload}/approve`).then((response) => response.data)
);

/* Post Application review */
export const rejectApplication = createAsyncThunk('products/rejectApplication', (payload) => 
  axios.post(`${approveApplicationUrl + payload}/reject`).then((response) => response.data)
);

/* Post Application review */
export const bulkApproveApplication = createAsyncThunk('products/bulkApproveApplication', (payload) => 
  axios.post(`${bulkApprovalUrl}`, payload).then((response) => response.data)
);

const accountSlice = createSlice({
  name: 'accounts',
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, (state) => {
      state.loading = true;
      state.accountList = [];
      state.error = null;
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      
      state.loading = false;
      state.accountList = action.payload;
      state.error = null;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.loading = false;
      state.accountList = [];
      state.error = action.error.message;
    });
    builder.addCase(approveApplication.pending, (state) => {
      state.loading = true;
      state.approvalStatus = [];
      state.approveError = null;
    });
    builder.addCase(approveApplication.fulfilled, (state, action) => {
      
      state.loading = false;
      state.approvalStatus = action.payload;
      state.approveError = null;
    });
    builder.addCase(approveApplication.rejected, (state, action) => {
      state.loading = false;
      state.approvalStatus = [];
      state.approveError = action.error.message;
    });
    builder.addCase(rejectApplication.pending, (state) => {
      state.loading = true;
      state.approvalStatus = [];
      state.error = null;
    });
    builder.addCase(rejectApplication.fulfilled, (state, action) => {
      
      state.loading = false;
      state.approvalStatus = action.payload;
      state.error = null;
    });
    builder.addCase(rejectApplication.rejected, (state, action) => {
      state.loading = false;
      state.approvalStatus = [];
      state.error = action.error.message;
    });

    // single application data
    builder.addCase(fetchSinlgeApplication.pending, (state) => {
      state.loading = true;
      state.singleApplication = null;
      state.error = null;
    });
    builder.addCase(fetchSinlgeApplication.fulfilled, (state, action) => {
      
      state.loading = false;
      state.singleApplication = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSinlgeApplication.rejected, (state, action) => {
      state.loading = false;
      state.singleApplication = null;
      state.error = action.error.message;
    });
    // Invite User
    builder.addCase(inviteUser.pending, (state) => {
      state.loading = true;
      state.userInvite = null;
      state.error = null;
    });
    builder.addCase(inviteUser.fulfilled, (state, action) => {
      
      state.loading = false;
      state.userInvite = action.payload;
      state.error = null;
    });
    builder.addCase(inviteUser.rejected, (state, action) => {
      state.loading = false;
      state.userInvite = null;
      state.error = action.error.message;
    });
  },
});

export default accountSlice.reducer;
