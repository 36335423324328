/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */

import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CollectionsIcon from '@mui/icons-material/Collections';
import { ProductList } from '../sections/@dashboard/products';
import { postEmployeeFile, propertyImage } from '../store/fileupload.slice';
import { getProfile } from '../store/profile.slice';
import { addNewProductPost, resetAddProduct } from '../store/products.slice';
import { addNewPropertyPost, propertyExist } from '../store/properties.slice';

const AddNewProperty = () => {
  const [open, setOpen] = useState(true);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const dispatch = useDispatch();
  const [desc, setDesc] = useState('');
  const [uploadError, setUploadError] = useState('');
  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;

  // eslint-disable-next-line prefer-const
  const [images, setImages] = useState([]);
  const productsState = useSelector((state) => state.products);
  const { newProduct } = productsState;

  const navigate = useNavigate();

  /* Form validity status */
  let isFormInvalid = true;

  /* Products API GET Call */
  useEffect(() => {
    dispatch(resetAddProduct());
    // if (profile.length !== 0) dispatch(getProfile(profile?.profile?.email));
  }, []);
  const [values, setValues] = useState({
    title: '',
    subtitle: '',
    description: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    nearest_town: '',
    price: '',
    area_size: '',
    tags: '',
    images: [],
    imageList: [],
    thumbnail: [],
  });
  const [debouncedproductName] = useDebounce(values.title, 1000);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const fileObj = [];
  const fileArray = [];
  useEffect(() => {
    setTagValue([]);
    for (let i = 0; i < autoCompleteValue.length; i++) {
      setTagValue(tagValue.concat({ value: autoCompleteValue[i] }));
    }
  }, [autoCompleteValue]);

  const addNewProperty = async (event) => {
    // eslint-disable-next-line prefer-const
    const payload = {
      title: values.title,
      subtitle: values.subtitle,
      description: desc,
      price: values.price,
      state: values.state,
      country: values.country,
      locations: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      nearest_town: values.nearest_town,
      property_tags: tagValue,
      images: values.images,
      area_size: values.area_size,
      thumbnail: values.thumbnail[0]?.file_path,
    };
    await dispatch(addNewPropertyPost(payload));

    setValues({
      title: '',
      subtitle: '',
      description: '',
      price: '',
      state: '',
      country: '',
      latitude: '',
      longitude: '',
      nearest_town: '',
      tags: '',
      images: [],
      imageList: [],
      thumbnail: [],
    });
    setDesc('');
    isFormInvalid = true;
    navigate('/dashboard/property-management');
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUploadError('');
    setOpen(false);
  };

  /* Check if the fields exists and are not empty */
  if (
    values.hasOwnProperty('title') &&
    values.title !== '' &&
    values.hasOwnProperty('subtitle') &&
    values.subtitle !== '' &&
    values.hasOwnProperty('state') &&
    values.state !== '' &&
    values.hasOwnProperty('country') &&
    values.country !== '' &&
    values.price !== '' &&
    values.price !== null &&
    values.area_size !== '' &&
    values.area_size !== null
  ) {
    isFormInvalid = false;
  } else {
    isFormInvalid = true;
  }
  /* Handle error in the Textfields */
  // eslint-disable-next-line consistent-return
  const handleError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
  };
  const ispropertyExist = useSelector((state) => state.properties);

  /* Handle the helper text in the Textfields */
  const handleText = (_fieldName, _helperText) => {
    if (ispropertyExist?.existError !== null) {
      isFormInvalid = true;
      return _helperText;
    }
    return null;
  };

  const handleNameError = (_fieldName) => {
    if (values[_fieldName] === '') {
      return true;
    }
    if (ispropertyExist?.existError !== null) {
      return true;
    }
    return false;
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: '',
    onUpdate: ({ editor }) => {
      const json = editor.getHTML();
      setDesc(json);
    },
  });
  /* useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    editor?.commands?.setContent(desc);
  }, [desc]); */
  useEffect(() => {
    const payload = {
      property_title: values.title,
    };
    dispatch(propertyExist(payload));
  }, [debouncedproductName]);
  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <div>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          strike
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          code
        </Button>
        {/* <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </Button> */}
        {/* <Button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </Button> */}
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          paragraph
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </Button>
        <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          redo
        </Button>
      </div>
    );
  };

  const uploadMultipleFiles = async (e) => {
    const bodyFormData = new FormData();

    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      bodyFormData.append('files', fileObj[0][i]);
    }

    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(propertyImage(payload));

    if (response?.error) {
       setUploadError('Please check the file size or type')
    }
    if (response?.payload?.length > 0) {
      for (let i = 0; i < response.payload.length; i++) {
        // images.push(response.payload[i])
        setImages([...images, response.payload[i]]);
        values.images = Object.assign([], values.images);
        values.images.push({
          image_id: response.payload[i]?.image_id,
          url: response.payload[i]?.image_url,
        });
      }
    }
  };
  useEffect(() => {
    setTagValue([]);
    for (let i = 0; i < autoCompleteValue.length; i++) {
      //  setTagValue(tagValue.concat({ value: autoCompleteValue[i] }));
      setTagValue((tagValue) => [{ value: autoCompleteValue[i] }, ...tagValue]);
    }
  }, [autoCompleteValue]);

  const uploadThumbnail = async (e) => {
    const bodyFormData = new FormData();

    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      bodyFormData.append('files', fileObj[0][i]);
    }

    const payload = {
      userId: profile?.admin_user_id,
      file_content: bodyFormData,
    };

    const response = await dispatch(propertyImage(payload));
    console.log(response,"xgfdg")
    if (response.payload.length > 0) {
      setValues({ ...values, thumbnail: response.payload });
    }
  };

  const numberOnlyTextField = (e) => {
    const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const textOnlyTextField = (e) => {
    const regex = /^[a-zA-Z]|[ ]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Property details
          </Typography>
          {newProduct.length > 0 && productsState?.error == null && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                New item has been added
              </Alert>
            </Snackbar>
          )}
          {productsState?.error != null && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {productsState?.error}
              </Alert>
            </Snackbar>
          )}
          {uploadError !== '' && (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {uploadError}
              </Alert>
            </Snackbar>
          )}
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} justifyContent="center">
              <form autoComplete="off">
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Avatar
                        src={values?.thumbnail?.length > 0 ? values?.thumbnail[0]?.image_url : ''}
                        sx={{
                          height: 64,
                          mb: 2,
                          width: 64,
                        }}
                      >
                        <CollectionsIcon />
                      </Avatar>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions className="justify-center flex">
                    <Button variant="text" component="label">
                      Upload thumbnail
                      <input hidden accept="image/*" type="file" onChange={uploadThumbnail} />
                    </Button>
                  </CardActions>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Title"
                          name="title"
                          onChange={(event) => handleChange(event, values.title)}
                          required
                          value={values.title}
                          helperText={handleText('title', 'The title name already exists!')}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Subtitle"
                          name="subtitle"
                          onChange={(event) => handleChange(event, values.subtitle)}
                          required
                          value={values.subtitle}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Area size"
                          name="area_size"
                          onChange={numberOnlyTextField}
                          required
                          value={values.area_size}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ startAdornment: <InputAdornment position="start">&#8377;</InputAdornment> }}
                          fullWidth
                          label="Price"
                          name="price"
                          onChange={numberOnlyTextField}
                          required
                          value={values.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormLabel>Description</FormLabel>
                        <div>
                          <>
                            <MenuBar editor={editor} />
                            <EditorContent editor={editor} className="editor-content" />
                          </>
                        </div>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="State"
                          name="state"
                          onChange={textOnlyTextField}
                          required
                          value={values.state}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Country"
                          name="country"
                          onChange={textOnlyTextField}
                          required
                          value={values.country}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Latitude"
                          name="latitude"
                          onChange={handleChange}
                          value={values.latitude}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Longitude"
                          name="longitude"
                          onChange={handleChange}
                          value={values.longitude}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Nearest town"
                          name="nearest_town"
                          onChange={(event) => handleChange(event, values.nearest_town)}
                          value={values.nearest_town}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={[]}
                          value={autoCompleteValue}
                          onChange={(e, newval, reason) => {
                            setAutoCompleteValue(newval);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Tags"
                              placeholder=""
                              onKeyDown={(e) => {
                                if (e.code === 'Enter' && e.target.value) {
                                  setAutoCompleteValue(autoCompleteValue.concat(e.target.value));
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
                      Media images
                    </Typography>
                    {values.images?.length > 0 && <ProductList products={values.images} />}
                    <div className="h-4/5  ">
                      <div className="p-6 space-y-6 h-4/5 ">
                        <div className="flex justify-center items-center w-full">
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col justify-center items-center w-full h-64 bg-slate-100 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-slate-100
                        dark:bg-slate-100 hover:bg-slate-100 dark:border-gray-600 dark:hover:border-gray-500 "
                          >
                            <div className="flex flex-col justify-center items-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="mb-3 w-10 h-10 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop images
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPEG or JPG. Maximum size 4MB. </p>
                            </div>
                            <div className="form-group multi-preview">{fileArray.json}</div>
                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              onChange={uploadMultipleFiles}
                              multiple
                            />
                          </label>{' '}
                          <div className="form-group multi-preview">
                            {(fileArray || []).map((url) => (
                              <img src={url} alt="..." />
                            ))}
                          </div>
                          {/* <button type="button" className="btn btn-danger btn-block" onClick={uploadFiles}>Upload</button> */}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className="bg-green-700"
                      onClick={addNewProperty}
                      disabled={isFormInvalid}
                    >
                      Save details
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddNewProperty;
