import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialStateObject = {
  loading: false,
  fileUploadResponse: {
    loading: null,
    error: '',
    data: null,
  },
  error: '',
};

const fileUploadApiUrl = `${process.env.REACT_APP_API_BASE_URL}admin/product-management/products/upload-image`;

export const postEmployeeFile = createAsyncThunk('fileupload/postEmployeeFile', (payload) => 
  axios.post(`${fileUploadApiUrl}`, payload?.file_content).then((response) => response.data)
);

const propertyImageUpload = `${process.env.REACT_APP_API_BASE_URL}admin/properties/upload-image`;

export const propertyImage = createAsyncThunk('fileupload/propertyImage', (payload) => 
  axios.post(`${propertyImageUpload}`, payload?.file_content).then((response) => response.data)
);

const fileuploadSlice = createSlice({
  name: 'fileupload',
  /* file Upload */
  initialState: initialStateObject,
  extraReducers: (builder) => {
    builder.addCase(postEmployeeFile.pending, (state) => {
      state.fileUploadResponse.loading = true;
      state.fileUploadResponse.data = null;
      state.fileUploadResponse.error = '';
    });
    builder.addCase(postEmployeeFile.fulfilled, (state, action) => {
      state.fileUploadResponse.loading = false;
      state.fileUploadResponse.data = action.payload;
      state.fileUploadResponse.error = '';
    });
    builder.addCase(postEmployeeFile.rejected, (state, action) => {
      state.fileUploadResponse.loading = false;
      state.fileUploadResponse.data = null;
      state.fileUploadResponse.error = action.error.message;
    });
  },
});

export default fileuploadSlice.reducer;
