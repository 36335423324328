// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('apps-outline'),
  },
  {
    title: 'product management',
    path: '/dashboard/product-management',
    icon: icon('copy-outline'),
  },
  {
    title: 'property management',
    path: '/dashboard/property-management',
    icon: icon('shield-checkmark-outline'),
  },
  // {
  //   title: 'user management',
  //   path: '/dashboard/user',
  //   icon: icon('person-outline'),
  // },
  {
    title: 'Active Accounts',
    path: '/dashboard/account',
    icon: icon('document-attach-outline'),
  },
  {
    title: 'Approvals',
    path: '/dashboard/approvals',
    icon: icon('checkbox-outline'),
  },
  {
    title: 'content management',
    path: '/dashboard/marketing-content-management',
    icon: icon('newspaper-outline'),
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: icon('settings-outline'),
  },
  // {
  //   title: 'logout',
  //   path: '/dashboard/logout',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
