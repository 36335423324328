/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import { getAccounts } from '../store/accounts.slice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'application_id', label: 'Application Id', alignRight: false },
  { id: 'username', label: 'User Name', alignRight: false },
  { id: 'product_name', label: 'Product Name', alignRight: false },
  { id: 'no_of_shares', label: 'Number of Shares', alignRight: false },
  { id: 'payment_amount', label: 'Amount', alignRight: false },
  { id: 'date_of_purchase', label: 'Date of Purchase', alignRight: false },
  { id: 'time_of_purchase', label: 'Time of Purchase', alignRight: false },
  { id: 'payment_method_type', label: 'Mode of Payment', alignRight: false },
  //  {id:''},
];

export default function AccountsPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('date_of_purchase');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const dispatch = useDispatch();

  const payload = {
    type: 'APPROVED',
    rowsPerPage,
    page,
    order,
    orderBasedOn: orderBy,
    searchElement,
  };
  /* Accounts API GET Call */
  useEffect(() => {
    dispatch(getAccounts(payload));
  }, [rowsPerPage, page, order, debouncedsearchElement]);
  /* Get the State of the Accounts slice */
  const accountState = useSelector((state) => state.accounts);
  const { accountList } = accountState;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accountList?.items?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Helmet>
        <title> Investments </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Active Accounts
          </Typography>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholder="Search Investments..."/> */}
          <TextField
            className="m-6 w-1/2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search Product name or User name..."
            onChange={(event) => setsearchElement(event.target.value)}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={accountList?.items?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {accountList?.items?.map((row) => {
                    // eslint-disable-next-line camelcase
                    const {
                      application_id,
                      product_name,
                      total_price,
                      last_name,
                      no_of_shares,
                      first_name,
                      date_of_purchase,
                      payment_method_type,
                    } = row;
                    const selectedUser = selected.indexOf(product_name) !== -1;

                    return (
                      <>
                        <TableRow hover key={application_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}

                              <Typography variant="subtitle2" noWrap>
                                <TableCell align="left">{application_id}</TableCell>
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {first_name} {last_name}
                          </TableCell>
                          <TableCell align="left">{product_name}</TableCell>
                          <TableCell align="left">{no_of_shares}</TableCell>

                          <TableCell align="left">{total_price}</TableCell>

                          <TableCell align="left">{dayjs(date_of_purchase).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="left">{dayjs(date_of_purchase).format('hh:mm A')}</TableCell>
                          <TableCell align="left">{payment_method_type}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>

                {accountList?.items?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No results found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accountList?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
