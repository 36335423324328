/* eslint-disable camelcase */
import {
  Box,
  Card,
  Table,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { useDebounce } from 'use-debounce';
import { getProducts } from '../store/products.slice';
import { InProcess } from './dashboard/inprocess';
import { Rejected } from './dashboard/rejected';
import { Approved } from './dashboard/approved';
import { TotalProfit } from './dashboard/total-profit';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import { getApplicationCount } from '../store/profile.slice';

const TABLE_HEAD = [
  { id: 'number', label: '#', alignRight: false },
  { id: 'name', label: 'Product Name', alignRight: false },
  { id: 'status', label: 'Current Status', alignRight: false },
  { id: 'product_created_date', label: 'Created Date', alignRight: false },
  { id: 'product_created_time', label: 'Created Time', alignRight: false },
  { id: 'product_updated_date', label: 'Inactivated Date', alignRight: false },
  { id: 'product_updated_time', label: 'Inactivated Time', alignRight: false },
  { id: 'application_count', label: '# of Applications', alignRight: false },
  { id: 'total_investment', label: 'Total Investments', alignRight: false },
];
const DashboardAppPage = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('product_created_at');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchElement, setsearchElement] = useState('');

  const [debouncedsearchElement] = useDebounce(searchElement, 1000);

  const payload = {
    rowsPerPage: 5,
    page: 0,
    order: 'desc',
    orderBasedon: 'created_at',
    searchElement: '',
  };

  /* Products API GET Call */
  useEffect(() => {
    dispatch(getProducts(payload));
  }, []);
  const payload1 = {
    rowsPerPage,
    page,
    order,
    orderBasedon: orderBy,
    searchElement,
  };
  useEffect(() => {
    dispatch(getApplicationCount(payload1));
  }, [rowsPerPage, page, debouncedsearchElement]);

  /* Get the State of the Product slice */
  const profileState = useSelector((state) => state.profile);
  const { profile } = profileState;
  const { applicationCount } = profileState;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = applicationCount?.items?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <title>Investments</title>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3} className="mb-10">
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Approved count={profile?.dashboard?.applications_approved} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <InProcess count={profile?.dashboard?.applications_pending} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <Rejected count={profile?.dashboard?.applications_rejected} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalProfit sx={{ height: '100%' }} count={profile?.dashboard?.applications_total} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Products Overview
                </Typography>
              </Stack>

              <Card>
                {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholder="Search Investments..."/> */}
                <TextField
                  className="m-6 w-1/2"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Product name..."
                  onChange={(event) => setsearchElement(event.target.value)}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={applicationCount?.items?.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />

                      <TableBody>
                        {applicationCount?.items?.map((row, index) => {
                          // eslint-disable-next-line camelcase
                          const {
                            product_id,
                            is_active,
                            name,
                            product_created_at,
                            application_count,
                            total_investment,
                            product_updated_at,
                          } = row;
                          const selectedUser = selected.indexOf(product_id) !== -1;
                          return (
                            <>
                              <TableRow hover key={product_id} tabIndex={-1} selected={selectedUser}>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}

                                    <Typography variant="subtitle2" noWrap>
                                      <TableCell align="left">{index + 1}</TableCell>
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">{is_active.toString()}</TableCell>
                                <TableCell align="left">{dayjs(product_created_at).format('DD-MM-YYYY')}</TableCell>
                                <TableCell align="left">{dayjs(product_created_at).format('hh:mm A')}</TableCell>

                                <TableCell align="left">{dayjs(product_updated_at).format('DD-MM-YYYY')}</TableCell>
                                <TableCell align="left">{dayjs(product_updated_at).format('hh:mm A')}</TableCell>

                                <TableCell align="left">{application_count}</TableCell>
                                <TableCell align="left">{total_investment}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>

                      {applicationCount?.items?.length === 0 && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  No results found
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={applicationCount?.meta?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardAppPage;
