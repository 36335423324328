import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  isLoggedIn: false,
};

const signInSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.currentUser = action.payload.userAttributes;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
  },
});

export default signInSlice.reducer;
export const { signIn } = signInSlice.actions;
